<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'microsoft-mbs',
  primary_color: '#8051D0',
  secondary_color: '#30E5D0',
  header: {
    background: {
      image: `/projekt/microsoft-mbs/elbworx-projekt-mbs-header-1600.webp`,
    },
    project: 'Microsoft #MBS',
    headline: 'Erfolgsevent in neuem Kleid',
    headline_low_contrast_fix: true,
    info_card: {
      description: 'Auf Wiedersehen, Partnerkonferenz. Hallo DPK.',
      client: 'Microsoft Business Summit',
      roles: ['Veranstaltungsdesign', 'Corporate Design'],
      project_duration: 'Seit 2015',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3>Der Erfolg von Microsoft geht Hand in Hand mit dem Erfolg seiner Partner</h3>

      <p>
        Partner-Veranstaltungen spielen darum eine Schlüsselrolle in der Geschäftsstrategie von
        Microsoft. Als wir 2015 mit der Entwicklung eines neuen Veranstaltungskonzepts beauftragt
        wurden, war die Deutsche Partnerkonferenz das wichtigste Microsoft Business-Event in
        Deutschland und die zweitgrößte Partnerveranstaltung von Microsoft weltweit. Um neue
        Zielgruppen zu erschließen, ging es um nicht weniger als die Neuerfindung des Events:
        moderner, frischer und authentischer zu sein als bisher. Die Partnerkonferenz sollte wachsen
        – und mit ihr auch das Interesse an ihr.
      </p>
    </template>

    <Padding>
      <Device
        class="mx-auto w-full max-w-6xl"
        :element="{
          device_type: 'desktop',
          frame_color: '#8051D0',
        }"
      >
        <video
          :src="`${projectPath}elbworx-mbs-1080.mp4`"
          muted
          class="h-full object-cover"
          autoplay
          loop
          playsinline
        />
      </Device>
    </Padding>

    <div class="mt-24">
      <Tilt>
        <Image
          class="max-h-[80vh] w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mbs-collage1a-1523.webp`"
        />
      </Tilt>
      <Tilt class="bg-primary pt-tilt md:-mt-tilt">
        <Grid class="-mt-tilt">
          <div class="col-span-7 h-full">
            <Image
              class="h-full w-full object-cover"
              :src="`${projectPath}elbworx-projekt-mbs-collage1b-1342.webp`"
            />
          </div>
          <Tilt contain class="col-span-5">
            <Padding class="pb-8 md:pb-28 md:pt-20">
              <Text class="!text-white">
                <h3 class="!text-white">Offen für Neues</h3>
                <p>
                  Um den bisherigen Erfolg der Veranstaltung fortzuschreiben, mussten wir vor allem
                  eines sein: zeitgemäß. Offen für Neues. Auch und gerade in der Kommunikation. Um
                  die treibenden Kräfte von morgen anziehen und neue Zielgruppen erschließen zu
                  können, galt es einen großen Bogen um leere Versprechen und Kraftmeierei zu machen
                  – und stattdessen auf eine authentische Grundhaltung zu bauen, verbunden mit der
                  festen Zusage an den Partner: Die Partnerkonferenz bringt dein Business nach
                  vorne.
                </p>
              </Text>
            </Padding>
          </Tilt>
        </Grid>
      </Tilt>
      <Tilt class="-mt-tilt">
        <div>
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-mbs-collage1c-3840.webp`"
          />
        </div>
      </Tilt>
    </div>

    <Grid class="-mt-tilt grid-flow-row-dense">
      <Padding only="left" class="md:contents">
        <div class="col-span-3 ml-auto max-w-md md:col-end-13">
          <Image
            class="w-full object-cover"
            :src="`${projectPath}elbworx-projekt-mbs-3dgrafik-960.webp`"
          />
        </div>
      </Padding>
      <div class="col-span-6 max-md:-mt-36 md:col-start-4 md:mt-36 md:pt-tilt">
        <Padding class="md:contents">
          <Text>
            <p>
              Auf der Basis dieser Überlegungen wurde die altehrwürdige »Deutsche Partnerkonferenz«
              umbenannt in »DPK – Einen Schritt voraus.«
            </p>
            <p>
              Der neue Veranstaltungsname konzentrierte sich ganz auf den Mehrwert aus
              Partner-Sicht: Erweiterung des Netzwerks, Teilen von Wissen, Erhöhung der eigenen
              Chancen. Um den zeitgemäßen und zukunftsweisenden Charakter der Konferenz zu
              unterstreichen, stellten wir dem Veranstaltungsnamen jeweils ein Jahresthema zur
              Seite, ergänzt um ein Hashtag und die aktuelle Jahreszahl.
            </p>
          </Text>
        </Padding>
      </div>
    </Grid>

    <div>
      <Tilt contain class="bg-[#8051D0]">
        <ImageSwiper
          slide-button-class="bg-[#30E5D0]"
          :space-between="28"
          :images="[
            { url: `${projectPath}elbworx-projekt-mbs-slider1-2560.webp`, alt: 'auto' },
            { url: `${projectPath}elbworx-projekt-mbs-slider2-2560.webp`, alt: 'auto' },
            { url: `${projectPath}elbworx-projekt-mbs-slider3-2560.webp`, alt: 'auto' },
          ]"
        />
      </Tilt>

      <div class="-mt-tilt">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mbs-stuff-1280.png`"
        />
      </div>
    </div>

    <Grid>
      <Padding only="left" class="col-span-5 col-start-2 place-self-end">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mbs-collage2a-1280.webp`"
        />
      </Padding>
      <div class="col-span-6">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mbs-collage2b-1920.webp`"
        />
      </div>
      <Padding only="right" class="col-span-11">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mbs-collage2c-2560.webp`"
        />
      </Padding>
      <Padding only="left" class="col-span-8">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mbs-collage2d-1920.webp`"
        />
      </Padding>
      <Padding only="right" class="col-span-4">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-mbs-collage2e-960.webp`"
        />
      </Padding>
    </Grid>
  </Project>
</template>
